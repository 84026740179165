const mode = "PROD";
// const mode = "DEV";

export const BASE_URL =
  mode === "PROD"
    ? "https://api.scoutheim.com"
    : "https://api.scout-heim.vytech.co";

export const BASE_URL_UPLOAD =
  mode === "PROD"
    ? "https://api.scoutheim.com/uploads"
    : "https://api.scout-heim.vytech.co/uploads";
